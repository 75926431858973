<template>
  <div>
    <el-dialog
      :title="data.id ? '编辑轮播图' : '添加轮播图'"
      :visible.sync="dialogVisible"
      :before-close="outClose"
      width="500px"
    >
      <div class="from">
        <div class="list-dialog">
          <div class="list">
            <p style="margin-bottom: 10px;">
              标题
              <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
            </p>
            <el-input v-model="data.title" placeholder="请输入标题"></el-input>
          </div>
        </div>

        <div class="list-dialog">
          <div class="list">
            <p style="margin-bottom: 10px;">
              副标题
              <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
            </p>
            <el-input v-model="data.con" placeholder="请输入副标题"></el-input>
          </div>
        </div>

        <div class="list-dialog">
          <div class="list">
            <p style="margin-bottom: 10px;">
              图片
              <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
            </p>

            <div class="itemIms" v-if="data.imgs">
              <div class="img_list">
                <img :src="data.imgs" alt />
                <p class="el-icon-delete-solid" @click="logoRemove()"></p>
              </div>
            </div>
            <!-- <el-input v-model="con.projectStatus" placeholder="请输入产品分类"></el-input> -->
            <el-upload
              action="/upload"
              ref="logoImage"
              list-type="picture-card"
              :headers="uploadHeaders"
              :on-remove="logoRemove"
              :on-success="logoUploadSuccess"
              :auto-upload="true"
              :limit="1"
              v-if="!data.imgs"
            >
              <i class="el-icon-upload"></i>
            </el-upload>
          </div>
        </div>
      </div>

      <template #footer>
        <div type="flex" slot="footer" class="dialog-footer">
          <el-button type="info" @click="outClose()">取 消</el-button>
          <el-button type="primary" @click="submit()">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { addBanner, editBanner } from "@/api/banner";
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    fid: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      total: 0
    };
  },
  watch: {
    dialogVisible(data) {
      if (!data) {
        for (let key in this.data) {
          this.data[key] = "";
        }
      }
    }
  },
  methods: {
    logoUploadSuccess(res) {
      this.$refs.logoImage.clearFiles();
      if (res.code == 200) {
        this.data.imgs = "http://www.zhexinlawyer.com/" + res.data;
      } else {
        this.$message.error(res.msg);
      }
      this.$forceUpdate();
    },

    logoRemove() {
      this.data.imgs = "";
      this.$forceUpdate();
    },

    submit() {
      if (this.data.id) {
        this.edit();
      } else {
        this.add();
      }
    },
    async add() {
      await addBanner({
        imgs: this.data.imgs,
        title: this.data.title,
        con: this.data.con
      }).then(res => {
        if (res.code == 200) {
          this.$message.success("添加成功");
          this.$emit("outClose");
        }
      });
    },
    async edit() {
      await editBanner(this.data.id, this.data).then(res => {
        if (res.code == 200) {
          this.$message.success("编辑成功");
          this.$emit("outClose");
        }
      });
    },
    outClose() {
      this.$emit("outClose");
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 10px 20px;
}
/* 弹出框居中显示 */
/deep/ .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
}
/* 弹出框超出部分滑动 */
/deep/ .el-dialog__body {
  overflow: hidden;
  overflow-y: auto;
}
.list-dialog {
  //   margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  .list {
    width: 100%;
    .el-input {
      width: 98%;
    }
  }
}
.search {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .left {
    width: 100%;
    display: flex;
    align-items: flex-end;
    .box {
      display: flex;
      .el-input {
        width: 300px;
        margin-right: 20px;
        margin-left: 20px;
      }
    }
  }
  .right {
    width: 40%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .box {
      display: flex;
    }
  }
}
.table {
  margin-top: 30px;
  .t-item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
    .t-list {
      width: 19%;
      background: #fff;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.3);
      padding-top: 20px;
      border-radius: 5px;
      margin-bottom: 1%;
      position: relative;
      p {
        text-align: center;
        margin-bottom: 0px !important;
      }
      .title {
        font-weight: 800;
        font-size: 15px;
        opacity: 0.5;
      }
      .img {
        width: 100%;
        height: auto;
        border-radius: 50%;
        object-fit: cover;
        display: block;
        margin: 20px auto;
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          display: block;
          margin: auto;
        }
      }
      .number {
        color: #000;
        font-weight: 800;
        font-size: 14px;
        opacity: 0.8;
      }
      .value {
        color: #000;
        opacity: 0.3;
        font-size: 12px;
        margin-top: 5px;
      }
      .datas {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        position: relative;
        top: 13px;
        padding-bottom: 20px;
        .box {
          position: absolute;
          left: 38%;
          transform: translateX(-30%);
          img {
            width: 30px;
            height: 30px;
            border: 1px solid #ccc;
            border-radius: 50%;
            position: absolute;
          }
        }
      }
      .time {
        font-weight: 800;
        font-size: 13px;
        padding-bottom: 20px;
        opacity: 0.5;
        margin-top: 5px;
      }
      .edit {
        position: absolute;
        right: 18px;
        top: 15px;
        color: #000;
        opacity: 0.4;
        font-weight: 800;
        cursor: pointer;
      }
    }
  }
}
.list {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 13px;
  p {
    font-size: 15px;
    color: rgba(125, 133, 146, 1);
    margin-bottom: 10px;
  }
  .el-input {
    width: 100%;
  }
}

/deep/ .ql-snow .ql-editor img {
  max-width: 30% !important;
}

.itemIms {
  width: auto;
  display: flex;
  .img_list {
    width: 110px;
    height: 110px;
    margin-right: 10px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
    p {
      position: absolute;
      width: 100%;
      height: 100%;
      line-height: 110px;
      background: rgba(0, 0, 0, 0.8);
      top: 0;
      color: #fff;
      text-align: center;
      cursor: pointer;
      opacity: 0;
      transition: 0.3s;
      border-radius: 5px;
      text-align: center;
      display: block;
      font-size: 20px;
    }
  }
  .img_list:hover p {
    opacity: 1;
  }
}
</style>