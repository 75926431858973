import request from '@/utils/request'

export function getList() {
    return request({
        url: '/banner-list/', 
        method: 'get'
    })
}

export function addBanner(data) {
    return request({
        url: '/banner-save', 
        method: 'post',
        data
    })
}

export function editBanner(id,data) {
    return request({
        url: '/banner-edit/' + id, 
        method: 'post',
        data
    })
}

export function delBanner(id) {
    return request({
        url: '/banner-delete/' + id, 
        method: 'post',
    })
}
